import { FaSearch } from 'react-icons/fa';
import { SHOW_COMMEMORATE } from 'src/constants';
import { SOCIAL_LIST } from 'src/constants/layout';
import WidgetLogin from 'src/services/member-auth/components/widget-login';
import { SHOW_MEMBER } from 'src/services/member-auth/constants';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { isRender } from 'src/utils/device';
import styled from 'styled-components';

const WidgetFollow = ({ eventDataLayer, handleSearch, sessionSevId }) => {
  return (
    <>
      <WidgetWrapper gray={SHOW_COMMEMORATE === 'true'} id='widget-follow'>
        {SOCIAL_LIST.map((item, index) => (
          <a
            tabIndex={-1}
            target='_blank'
            key={index}
            href={item?.link}
            rel='noopener noreferrer nofollow'
            aria-label='social'
            title='social'
            //! DATALAYER
            onClick={() =>
              sendDataLayerGlobal({
                ...eventDataLayer,
                data: {
                  title: item?.name,
                  heading: item?.name,
                  index: index + 1
                }
              })
            }
          >
            <div className={`bg-item --${item.name || 'default'}`}>{item.icon}</div>
          </a>
        ))}

        <div className={'bg-item --search'} onClick={() => handleSearch()}>
          <FaSearch size={14} />
        </div>
        {isRender('desktop') && SHOW_MEMBER === 'true' && <WidgetLogin sessionSevId={sessionSevId} />}
      </WidgetWrapper>
    </>
  );
};

const WidgetWrapper = styled.div`
  #widget-login {
    margin-left: 5px;
  }
  display: flex;
  align-items: center;
  a {
    filter: ${props => (props.gray ? 'grayscale(1)' : '')};
    margin-left: 15px;
    @media (max-width: 380px) {
      margin-left: 8px;
    }
  }
  a:first-child {
    margin-left: 0px;
  }
  .bg-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid #fff;

    &.--default,
    &.--search {
      margin-left: 15px;
      @media (max-width: 380px) {
        margin-left: 8px;
      }
      svg {
        color: #5a5a5a;
      }
    }
    &.--facebook {
      svg {
        color: #304a7d;
      }
    }
    &.--x {
      svg {
        color: #000000;
      }
    }
    &.--line {
      svg {
        color: #00b900;
      }
    }

    &:hover {
      &.--facebook {
        border: 1px solid #304a7d;
      }
      &.--x {
        border: 1px solid #676767;
      }
      &.--line {
        border: 1px solid #00b900;
      }
      &.--search {
        border: 1px solid #5a5a5a;
      }
    }
  }
`;
export default WidgetFollow;
