import { BsLine, BsTwitterX } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import { APP_IMG, APP_URL } from 'src/constants';

/* -------------------------------------------------------------------------- */
/*                                   HEADER                                   */
/* -------------------------------------------------------------------------- */

export const IMAGE_NAME = 'logo-192.png'; // logo-192.png
export const IMAGE_DEFAULT = `${APP_IMG}/default.jpg`; // default image
export const WEB_TITLE = 'ข่าวด่วน ข่าวล่าสุด ข่าววันนี้ วิเคราะห์ข่าวเศรษฐกิจ โดยกรุงเทพธุรกิจ';
export const WEB_DESC = 'กรุงเทพธุรกิจออนไลน์ นำเสนอข่าววันนี้ ข่าวด่วน ข่าวล่าสุดทั้งหมด เกี่ยวกับข่าวธุรกิจ ข่าวการเงิน ข่าวการค้า วิเคราะห์ข่าวเศรษฐกิจ ข่าวการลงทุน เพื่อการตัดสินใจอย่างมืออาชีพ';
export const WEB_KEYWORD = 'อ่านข่าวออนไลน์, กรุงเทพธุรกิจ, ข่าวเด่นประจำวัน, ข่าวโซเซียล, ข่าวธุรกิจ, การเงิน, เศรษฐกิจต่างประเทศ, คลิปข่าว, ข่าวบันเทิง';
export const WEB_NAME = 'bangkokbiznews';
export const WEB_LOGO = 'logo.png';

// export const TWITTER = {
//   HANDLE: 'กรุงเทพธุรกิจ',
//   SITE: '@ktnewsonline'
// };

export const STATIC_FILE = {
  FB_APP_ID: '192332974272649',
  FB_PAGES: '135182934814',
  ICON_LOGO: 'logo.svg',
  ICON_MASK: 'bangkokbiznews.png',
  ICON_FAV: 'favicon.ico',
  ICON_APPLE: 'logo-apple-touch.png'
};

/* -------------------------------------------------------------------------- */
/*                                   FOOTER                                   */
/* -------------------------------------------------------------------------- */

export const WEB_URL = {
  WEB: `${APP_URL}/`,
  FACEBOOK: 'https://www.facebook.com/bangkokbiznews',
  TWITTER: 'https://twitter.com/ktnewsonline',
  YOUTUBE: 'https://www.youtube.com/channel/UCwAoYpzi-LmKhRXlW4bMdBA',
  LINE: 'https://line.me/R/ti/p/%40rvb8351i',
  INSTAGRAM: 'https://www.instagram.com/bangkokbiznews/'
};

export const ADDRESS_ORG = {
  CONTENT: 'บริษัท กรุงเทพธุรกิจ มีเดีย จำกัด 1858/126 ชั้นที่ 30 ถนนเทพรัตน แขวงบางนาใต้ เขตบางนา',
  PROVINCE: 'กรุงเทพฯ',
  REGION: 'TH',
  POSTCODE: '10260',
  COUNTRY: 'THAILAND'
};

export const SOCIAL_LIST = [
  { id: 1, icon: <FaFacebookF id='facebook' size={16} />, link: WEB_URL.FACEBOOK, name: 'facebook', className: 'icon-facebook' },
  { id: 2, icon: <BsTwitterX id='x' size={16} />, link: WEB_URL.TWITTER, name: 'x', className: 'icon-x' },
  { id: 3, icon: <BsLine id='line' size={16} />, link: WEB_URL.LINE, name: 'line', className: 'icon-line' }
];
